import { isNotNull, isString, isTypeOf, keys, omit, sortBy, uniq } from '@partstech/ui/utils';
import { DEFAULT_FILTERS_SORT_INDEX, PREDEFINED_FILTERS } from 'constant';
import { Availability } from 'types/search';
import { formatAttributeName } from '../../product/attributes/formatAttributeName';
import { applyFiltersToProduct } from './applyFiltersToProduct';
import { createAvailabilityOption } from './createAvailabilityOption';
import { createBrandOption } from './createBrandOption';
import { createEnhancedAvailabilityOptions } from './createEnhancedAvailabilityOptions';
import { createEnhancedStockOption } from './createEnhancedStockOption';
import { createOption } from './createOption';
import { createPartTypeOption } from './createPartTypeOption';
import { createRebateOption } from './createRebateOption';
import { getAttributeRangeLabels, getAttributeRangeStep, splitRanges } from './dynamicAttributes';
import { getAttributeOptionsByName } from './getAttributeOptionsByName';
import { getAvailabilityPriority } from './getAvailabilityPriority';
import { getEmptyAttributeValueByName } from './getEmptyAttributeValueByName';
import { getMatchingSets, MATCHING_SETS_VALUE } from './getMatchingSets';
import { groupFilterOptions } from './groupFilterOptions';
import { isAvailableOnSection } from './isAvailableOnSection';
import { isDynamicAttributeFilterName } from './isDynamicAttributeFilterName';
import { makeAttributeFilterOptions } from './makeAttributeFilterOptions';
import { sortAvailabilityOptions } from './sortAvailabilityOptions';
import type { AttributeName } from 'constant';
import type { AvailabilityLine, Product } from 'models';
import type {
  CheckedFilters,
  CreateFiltersConfig,
  DynamicAttributeFilterName,
  DynamicAttributeName,
  DynamicAttributeValues,
  Filter,
  FilterOption,
} from 'types/search';

type OptionCreator<S = Product> = (optionSource: S) => FilterOption;

const createDynamicAttributeOptions = (
  name: DynamicAttributeName,
  checkedOptions: FilterOption[],
  attributeValues?: DynamicAttributeValues
) => {
  const emptyValue = getEmptyAttributeValueByName(name);

  const rangeStep = getAttributeRangeStep(name, 'filters');

  if (rangeStep) {
    const ranges = attributeValues ? splitRanges(attributeValues, rangeStep) : [];
    const rangeLabels = getAttributeRangeLabels(ranges, rangeStep);

    return ranges
      .map((attribute, index) => (attribute[0] ? createOption(attribute[0], `${rangeLabels[index]}`) : null))
      .filter(isNotNull);
  }

  const attributesOptions = attributeValues?.map((attribute) => createOption(attribute, attribute)) || [];

  return groupFilterOptions([...checkedOptions, ...attributesOptions, createOption(emptyValue, emptyValue)]);
};

const createStoreAvailabilityOption = (line: AvailabilityLine) => {
  if (line.group !== null) {
    return createOption(line.group, line.group, 1);
  }

  return createOption(line.id ? `${line.id}` : line.name, line.name, 1);
};

const createStoreAvailabilityOptions = (
  products: Product[],
  checkedOptions: FilterOption[],
  config: CreateFiltersConfig
) => {
  if (!config.shouldUseStoreAvailability) {
    return checkedOptions;
  }

  const groupedOptions = groupFilterOptions(
    products.flatMap((product) => product.quote?.lines?.map(createStoreAvailabilityOption) ?? [])
  );

  const sortedOptions = sortAvailabilityOptions(groupedOptions, config.supplierId);

  const optionValues = sortedOptions.map((option) => option.value);

  const options = sortedOptions.reduce(
    (list, option, index) => [
      ...list,
      {
        ...option,
        count: products.reduce((count, product) => {
          const previousValues = optionValues.slice(0, index + 1);

          const lines = product?.quote?.lines ?? [];
          const lineIds = lines.map((line) => `${line.group ?? line.id ?? line.name}`);

          const shouldCount = lineIds.some((lineId) => previousValues.some((value) => value === lineId));

          return shouldCount ? count + 1 : count;
        }, 0),
      },
    ],
    [] as FilterOption[]
  );

  return groupFilterOptions([...checkedOptions, ...options]);
};

const createStoreAvailabilityFilter = (
  products: Product[],
  checkedOptions: FilterOption[],
  config: CreateFiltersConfig
): Filter => ({
  name: 'storeAvailability',
  label: 'Availability',
  options: createStoreAvailabilityOptions(products, checkedOptions, config),
  isCollapsed: false,
  sortIndex: 10,
  cascade: true,
});

const createFilterOptions = (products: Product[], checkedOptions: FilterOption[], optionCreator: OptionCreator) => {
  const options = products.map(optionCreator);

  return groupFilterOptions([...checkedOptions, ...options]);
};

const availabilitySortValues: string[] = [
  Availability.IN_STOCK,
  Availability.BACK_ORDER,
  Availability.SPECIAL_ORDER,
  Availability.OUT_OF_STOCK,
];

const createAvailabilityFilter = (
  products: Product[],
  checkedOptions: FilterOption[],
  config: CreateFiltersConfig
): Filter => ({
  name: 'availability',
  label: 'Availability',
  options: createFilterOptions(products, checkedOptions, createAvailabilityOption(1)),
  isCollapsed: false,
  sortIndex: 10,
  orderBy: (optionA, optionB) =>
    availabilitySortValues.indexOf(optionA.value) - availabilitySortValues.indexOf(optionB.value),
  isHidden: config.shouldUseStoreAvailability,
});

const createEnhancedAvailabilityFilter = (
  products: Product[],
  checkedOptions: FilterOption[],
  config: CreateFiltersConfig
): Filter => {
  const stockAvailabilityOptions = products.map(createEnhancedStockOption(1));
  const availabilityOptions = createEnhancedAvailabilityOptions(products, config);

  const combinedEnhancedAvailabilityOptions = [...stockAvailabilityOptions, ...availabilityOptions].map((option) => ({
    ...option,
    checked: checkedOptions.some((checked) => checked.value === option.value),
  }));

  return {
    name: 'availability',
    label: 'Availability',
    options: groupFilterOptions(combinedEnhancedAvailabilityOptions),
    isCollapsed: false,
    sortIndex: 10,
    orderBy: (optionA, optionB) => getAvailabilityPriority(optionA.value) - getAvailabilityPriority(optionB.value),
    mixedSelection: true,
    cascade: false,
  };
};

const createBrandFilter = (products: Product[], checkedOptions: FilterOption[]): Filter => ({
  name: 'manufacturers',
  label: 'Brand',
  options: createFilterOptions(products, checkedOptions, createBrandOption(1)),
  isCollapsed: false,
  sortIndex: 25,
  orderBy: 'name',
});

const createPartTypeFilter = (products: Product[], checkedOptions: FilterOption[]): Filter => ({
  name: 'parts',
  label: 'Part Type',
  options: createFilterOptions(products, checkedOptions, createPartTypeOption(1)),
  isCollapsed: false,
  sortIndex: 20,
  orderBy: 'name',
});

const createDynamicAttributeFilter = (
  name: DynamicAttributeFilterName,
  checkedOptions: FilterOption[],
  config: CreateFiltersConfig
): Filter | null => {
  const attributeName = name.split('.').pop() as string;
  /**
   * If dynamic attribute is described in ATTRIBUTE_OPTIONS, we can override
   * default filter data like label, sorting position, orderBy behavior
   */
  const attributeOptions = getAttributeOptionsByName(attributeName);

  const attributeValues = config.attributes[attributeName];

  return {
    name,
    label: attributeOptions?.label ?? attributeName,
    isCollapsed: Boolean(attributeOptions?.isCollapsed) && checkedOptions.length === 0,
    options: createDynamicAttributeOptions(attributeName, checkedOptions, attributeValues),
    sortIndex: attributeOptions?.usage.filters ?? DEFAULT_FILTERS_SORT_INDEX - 1,
    orderBy: attributeOptions?.orderBy ?? 'name',
  };
};

const addMatchingSetsOption = (options: FilterOption[], products: Product[], config: CreateFiltersConfig) => {
  if (!config.shouldUseMatchingSets) {
    return options;
  }

  const matchingSets = getMatchingSets(products);
  const matchingSetsCount = keys(matchingSets).length;
  const matchingSetsOption = createOption(MATCHING_SETS_VALUE, 'Matching Sets', matchingSetsCount);

  if (matchingSetsCount === 0) {
    return options;
  }

  return groupFilterOptions([matchingSetsOption, ...options]);
};

const createPositionFilter = (
  name: 'Position',
  products: Product[],
  checkedOptions: FilterOption[],
  config: CreateFiltersConfig
): Filter | null => {
  const attributeOptions = getAttributeOptionsByName(name);
  const options = makeAttributeFilterOptions(name, products, checkedOptions);

  return {
    name,
    label: attributeOptions?.label ?? formatAttributeName(name),
    options: addMatchingSetsOption(options, products, config),
    isCollapsed: (attributeOptions?.isCollapsed && checkedOptions.length === 0) || false,
    sortIndex: attributeOptions?.usage.filters ?? DEFAULT_FILTERS_SORT_INDEX,
    orderBy: (optionA, optionB) => {
      if (config.shouldUseMatchingSets) {
        if (optionA.value === MATCHING_SETS_VALUE && optionB.value !== MATCHING_SETS_VALUE) {
          return -1;
        }
        if (optionA.value !== MATCHING_SETS_VALUE && optionB.value === MATCHING_SETS_VALUE) {
          return 1;
        }
      }

      return optionA.name.localeCompare(optionB.name);
    },
  };
};

const createAttributeFilter = (
  name: AttributeName,
  products: Product[],
  checkedOptions: FilterOption[]
): Filter | null => {
  const attributeOptions = getAttributeOptionsByName(name);

  return {
    name,
    label: attributeOptions?.label ?? formatAttributeName(name),
    options: makeAttributeFilterOptions(name as AttributeName, products, checkedOptions),
    isCollapsed: (attributeOptions?.isCollapsed && checkedOptions.length === 0) || false,
    sortIndex: attributeOptions?.usage.filters ?? DEFAULT_FILTERS_SORT_INDEX,
    orderBy: attributeOptions?.orderBy ?? 'name',
  };
};

const createTireTreadDepth = (
  name: AttributeName,
  products: Product[],
  checkedOptions: FilterOption[]
): Filter | null => {
  const attributeOptions = getAttributeOptionsByName(name);

  return {
    name,
    label: attributeOptions?.label ?? formatAttributeName(name),
    options: makeAttributeFilterOptions(name, products, checkedOptions),
    isCollapsed: (attributeOptions?.isCollapsed && checkedOptions.length === 0) || false,
    sortIndex: attributeOptions?.usage.filters ?? DEFAULT_FILTERS_SORT_INDEX,
    orderBy: (optionA, optionB) => parseFloat(optionA.name) - parseFloat(optionB.name),
  };
};

const createTireQualityFilter = (
  name: AttributeName,
  products: Product[],
  checkedOptions: FilterOption[]
): Filter | null => {
  const attributeOptions = getAttributeOptionsByName(name);

  return {
    name,
    label: attributeOptions?.label ?? formatAttributeName(name),
    options: makeAttributeFilterOptions(name, products, checkedOptions),
    isCollapsed: (attributeOptions?.isCollapsed && checkedOptions.length === 0) || false,
    sortIndex: attributeOptions?.usage.filters ?? DEFAULT_FILTERS_SORT_INDEX,
    orderBy: (optionA, optionB) => {
      const optionANumeric = parseInt(optionA.name, 10) || 0;
      const optionBNumeric = parseInt(optionB.name, 10) || 0;

      return optionANumeric - optionBNumeric;
    },
  };
};

const createRebateFilter = (products: Product[], checkedOptions: FilterOption[]): Filter => ({
  name: 'HasRebate',
  label: 'Rebate',
  options: createFilterOptions(products, checkedOptions, createRebateOption(1)),
  isCollapsed: false,
  sortIndex: 2050,
  orderBy: 'name',
});

const sortOptions = (filter: Filter): Filter => {
  const emptyValue = getEmptyAttributeValueByName(filter.name);

  const emptyValueToEnd = (a: FilterOption, b: FilterOption) => {
    if (b.name === emptyValue) {
      return -1;
    }

    return a.name === emptyValue ? 1 : 0;
  };

  const getOrderedOptions = () => {
    const { orderBy } = filter;
    if (isTypeOf(orderBy, 'function')) {
      return filter.options.sort(orderBy);
    }

    if (orderBy instanceof Object && 'field' in orderBy && 'direction' in orderBy) {
      const options = sortBy(filter.options, orderBy.field);
      return orderBy.direction === 'asc' ? options : options.reverse();
    }

    if (isString(orderBy)) {
      return sortBy(filter.options, orderBy);
    }

    return filter.options.slice();
  };

  return { ...filter, options: getOrderedOptions().sort(emptyValueToEnd).slice() };
};

const removeFiltersByConfig = (config: CreateFiltersConfig) => (name: Filter['name']) => {
  const attributeOptions = getAttributeOptionsByName(name);

  if (name === 'availability' && !config.shouldUseAvailability) {
    return false;
  }

  if (name === 'storeAvailability' && !config.shouldUseStoreAvailability) {
    return false;
  }

  if (PREDEFINED_FILTERS.includes(name)) {
    return true;
  }

  if (isDynamicAttributeFilterName(name)) {
    return true;
  }

  if (!attributeOptions) {
    return true;
  }

  return Boolean(attributeOptions) && isAvailableOnSection('filters', attributeOptions);
};

const extractFilterNames = (
  products: Product[],
  checkedNames: Filter['name'][],
  config: CreateFiltersConfig
): Filter['name'][] => {
  const dynamicAttributesNames = keys(config.attributes);

  const productAttributeNames = products
    .flatMap((product) => product.attributes.map((attribute) => attribute.name))
    .filter((attributeName) => !dynamicAttributesNames.includes(attributeName)) as Filter['name'][];

  const dynamicAttributeNames = dynamicAttributesNames.map(
    (name) => `attributes.${name}`
  ) as DynamicAttributeFilterName[];

  const names = [...PREDEFINED_FILTERS, ...checkedNames, ...productAttributeNames, ...dynamicAttributeNames];

  return uniq(names).filter(removeFiltersByConfig(config));
};

const createFilter =
  (
    products: Product[],
    checkedFilters: Partial<Record<Filter['name'], FilterOption[]>>,
    checkedValues: Partial<CheckedFilters>,
    config: CreateFiltersConfig
  ) =>
  (name: Filter['name']): Filter | null => {
    const filteredProducts = products.filter((product) =>
      applyFiltersToProduct(omit(checkedValues, name), config)(product, products)
    );
    const checkedOptions = checkedFilters[name] ?? [];

    switch (true) {
      case name === 'availability' && config.isAvailabilityFilterEnhancements:
        return createEnhancedAvailabilityFilter(filteredProducts, checkedOptions, config);

      case name === 'availability' && !config.isAvailabilityFilterEnhancements:
        return createAvailabilityFilter(filteredProducts, checkedOptions, config);

      case name === 'parts':
        return createPartTypeFilter(filteredProducts, checkedOptions);

      case name === 'Position':
        return createPositionFilter('Position', filteredProducts, checkedOptions, config);

      case name === 'manufacturers':
        return createBrandFilter(filteredProducts, checkedOptions);

      case name === 'storeAvailability' && !config.isAvailabilityFilterEnhancements:
        return createStoreAvailabilityFilter(filteredProducts, checkedOptions, config);

      case name === 'HasRebate':
        return createRebateFilter(filteredProducts, checkedOptions);

      case name === 'TiresQuality':
        return createTireQualityFilter('TiresQuality', filteredProducts, checkedOptions);

      case name === 'TireTreadDepth':
        return createTireTreadDepth('TireTreadDepth', filteredProducts, checkedOptions);

      case isDynamicAttributeFilterName(name):
        return createDynamicAttributeFilter(name as DynamicAttributeFilterName, checkedOptions, config);

      default:
        return createAttributeFilter(name as AttributeName, filteredProducts, checkedOptions);
    }
  };

const isVisibleFilter =
  (checkedNames: Filter['name'][]) =>
  (filter: Filter | null): filter is NonNullable<Filter> => {
    if (filter === null) {
      return false;
    }

    const hasOptions = filter.options.length > 0;
    const hasMultipleOptions = filter.options.length > 1;
    const isChecked = checkedNames.includes(filter.name);

    return (filter.name === 'availability' && hasOptions) || hasMultipleOptions || (hasOptions && isChecked);
  };

export const createFilters = (
  products: Product[],
  checkedOptions: Partial<Record<Filter['name'], FilterOption[]>>,
  config: CreateFiltersConfig
): Filter[] => {
  const checkedNames = keys(checkedOptions);

  const checkedValues = checkedNames.reduce(
    (result, name) => ({ ...result, [name]: checkedOptions[name]?.map((option) => option.value) ?? [] }),
    {} as Partial<CheckedFilters>
  );

  return extractFilterNames(products, checkedNames, config)
    .map(createFilter(products, checkedOptions, checkedValues, config))
    .filter(isVisibleFilter(checkedNames))
    .sort((a, b) => a.sortIndex - b.sortIndex)
    .map(sortOptions);
};
