import { isEmpty } from '@partstech/ui/utils';
import { useCallback, useMemo } from 'react';
import { LOCAL_INVENTORY } from 'constant';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useSearchResults } from 'pages/SearchResults/SearchResultsContext';
import { usePunchoutInfo } from 'store/queries/punchoutInfo/usePunchoutInfo';
import { useSupplierAccounts } from './useSupplierAccounts';
import type { SupplierAccount } from 'models';

type Props = {
  supplierType: 'dealers' | 'aftermarket';
  type: 'parts' | 'tires';
};

export const useGuessSupplierAccountId = (
  { supplierType, type }: Props,
  { skip = false }: { skip?: boolean } = {}
): SupplierAccount['id'] | null => {
  const { accounts } = useSupplierAccounts({ supplierType, type });
  const { localInventory } = usePunchoutInfo();
  const {
    flags: { localInventory: localInventoryFeatureFlag },
  } = useLaunchDarkly();

  // TODO: Remove use of SearchResults context
  const { totals, isAllLoaded } = useSearchResults();

  const guessSupplierAccountId = useCallback(
    (index: number = 0) => {
      const account = accounts[index];

      const total = account?.id ? totals[Number(account.id)] : undefined;
      const localInventoryTotal = totals[LOCAL_INVENTORY];

      if (localInventoryFeatureFlag && localInventory && localInventoryTotal) {
        return String(LOCAL_INVENTORY);
      }

      if (total === 0) {
        return guessSupplierAccountId(index + 1);
      }

      if (isEmpty(total)) {
        return null;
      }

      return account?.id ?? null;
    },
    [accounts, localInventory, localInventoryFeatureFlag, totals]
  );

  const supplierAccountId = useMemo(() => {
    if (skip) {
      return null;
    }

    return guessSupplierAccountId();
  }, [guessSupplierAccountId, skip]);

  if (!supplierAccountId && isAllLoaded) {
    return accounts[0]?.id ?? null;
  }

  return supplierAccountId ?? null;
};
