import { PartTypeApplication, RecentSearchType } from 'shared/api';
import type { PartCategory } from './PartCategory';
import type { Vehicle } from './Vehicle';
import type {
  SearchEntryPointInterface,
  SearchQuery,
  SearchQueryConvertable,
  SearchRequestConvertable,
  SearchCatalogRequestConvertable,
  ToRequestParams,
  ToCatalogRequestParams,
  SearchLocalInventoryRequestConvertable,
  ToLocalInventoryResourcesParams,
} from 'features/searchForm';
import type { CatalogSearchInput, CreateRecentSearchInput, LocalInventorySearchInput, SearchInput } from 'shared/api';
import type { FullPartTypeFragment } from 'store/queries/partTypes/FullPartType.generated';

type Attribute = {
  name: string;
  priority: number;
  required: boolean;
  values?: string[] | null;
};

type LinkedApplication = {
  application: PartTypeApplication;
};

export class PartType
  implements
    SearchEntryPointInterface,
    SearchQueryConvertable,
    SearchRequestConvertable,
    SearchCatalogRequestConvertable,
    SearchLocalInventoryRequestConvertable
{
  static readonly TIRE_ID = '7636';

  static readonly ENGINE_OIL_ID = '12138';

  id: string;

  name: string;

  aliases: string[];

  application: PartTypeApplication;

  attributes: Attribute[] | null;

  category: PartCategory | undefined;

  subCategoryId: string | undefined;

  isDisabled: boolean | undefined;

  linkedApplications: LinkedApplication[];

  constructor(partTypeData: FullPartTypeFragment) {
    this.id = partTypeData.id;
    this.name = partTypeData.name;
    this.aliases = partTypeData.aliases ?? [];
    this.application = partTypeData.application;
    this.linkedApplications = partTypeData.linkedPartTypes ?? [];

    this.attributes = partTypeData.attributes
      ? [...partTypeData.attributes].sort((a, b) => a.priority - b.priority)
      : null;
  }

  getClassName() {
    return `fs-${this.getColumnTitle().toLowerCase()}-part-type`;
  }

  getColumnTitle(): string {
    return this.isFitted ? 'FITTED' : 'UNIVERSAL';
  }

  isVehicleRequired(): boolean {
    return this.isFitted;
  }

  setCategory(category: PartCategory) {
    this.category = category;
  }

  setSubCategoryId(subCategoryId: string) {
    this.subCategoryId = subCategoryId;
    return this;
  }

  isEqual(partType: PartType) {
    return this.id === partType.id;
  }

  /**
   * Part type _requires_ vehicle for search
   */
  get isFitted(): boolean {
    return this.application === PartTypeApplication.Fitted || (!this.isUniversal && !this.isUniversalFitted);
  }

  /**
   * Part type doesn't require vehicle for search
   */
  get isUniversal(): boolean {
    return (
      this.application === PartTypeApplication.Universal &&
      !this.linkedApplications.some(({ application }) => application === PartTypeApplication.Fitted)
    );
  }

  /**
   * Part type may require vehicle for search and may not
   */
  get isUniversalFitted(): boolean {
    return (
      this.application === PartTypeApplication.UniversalFitted &&
      !this.linkedApplications.some(({ application }) => application === PartTypeApplication.Fitted)
    );
  }

  get isTire(): boolean {
    return this.id === PartType.TIRE_ID;
  }

  hasAliasMatch(search: string): boolean {
    return this.aliases.some((alias) => alias.toLowerCase() === search.toLowerCase());
  }

  populateInput(): string {
    return this.name;
  }

  toString() {
    return this.name;
  }

  toQuery(): SearchQuery {
    return {
      part_text_id: this.id,
      part_text: this.name,
    };
  }

  setIsDisabled(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    return this;
  }

  toRequest({ accountId, attributes, vehicleId, vin, jobId }: ToRequestParams): SearchInput {
    return {
      partTypeAttribute: {
        accountId,
        attributes,
        partTypeIds: [this.id],
        vehicleId,
        vin,
        jobId,
      },
    };
  }

  toCatalogRequest({ attributes, vehicleId, vin }: ToCatalogRequestParams): CatalogSearchInput {
    return {
      partTypeAttribute: {
        attributes,
        partTypeIds: [this.id],
        vehicleId,
        vin,
      },
    };
  }

  toLocalInventoryRequest({ attributes, vehicleId }: ToLocalInventoryResourcesParams): LocalInventorySearchInput {
    return {
      partTypeAttribute: {
        attributes,
        partTypeIds: [this.id],
        vehicleId,
      },
    };
  }

  toRecentSearch(vehicle: Vehicle | null): CreateRecentSearchInput {
    return {
      type: vehicle ? RecentSearchType.Keyword : RecentSearchType.NonApp,
      partText: {
        id: this.id,
        text: this.name,
      },
      vehicleID: vehicle?.id,
      state: vehicle?.state,
      plate: vehicle?.plate,
      vin: vehicle?.vin,
    };
  }
}
