import { useMemo } from 'react';
import { createFullProductFromQuery } from 'factories';
import { createBaseLocalInventoryProductFromQuery } from 'factories/product';
import { useFailedProductTracking } from 'hooks/product';
import { useToggleRetailPrice } from 'hooks/retailPricing';
import { useSupplierAccounts } from 'hooks/supplierAccounts';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { api as generatedApi } from 'store/queries/product/GetProduct.generated';
import { useGetLocalInventoryProductQuery } from './GetLocalInventoryProduct.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetProduct: {
      providesTags: ['Product'],
    },
  },
});

const { useGetProductQuery } = api;

type Props = {
  accountId?: string | null;
  lineCardId?: string | null;
  localInventoryUID?: string;
  partNumberId: string;
  partTypeId?: string | null;
  vehicleId?: string | null;
  vin?: string | null;
};

export const useProduct = (props: Props, skip: boolean = false) => {
  const { accounts } = useSupplierAccounts();
  const {
    flags: { availabilityFilterEnhancements },
  } = useLaunchDarkly();

  const { isShown: showRetailPrice } = useToggleRetailPrice();

  const isLocalInventoryProduct = Boolean(props.localInventoryUID);

  const productQuery = useGetProductQuery(props, {
    skip: skip || isLocalInventoryProduct,
  });

  const localInventoryProductQuery = useGetLocalInventoryProductQuery(
    { ...props, localInventoryUID: props.localInventoryUID ?? '' },
    { skip: skip || !isLocalInventoryProduct }
  );

  const data = useMemo(() => {
    if (isLocalInventoryProduct) {
      return localInventoryProductQuery;
    }

    return productQuery;
  }, [isLocalInventoryProduct, localInventoryProductQuery, productQuery]);

  const account = useMemo(() => accounts.find((item) => item.id === props.accountId), [accounts, props.accountId]);
  const product = useMemo(() => {
    const params = {
      account,
      vehicleId: props.vehicleId,
      filterEnhancements: availabilityFilterEnhancements,
      showRetailPrice,
    };

    if (localInventoryProductQuery.currentData?.localInventoryProduct) {
      return createBaseLocalInventoryProductFromQuery({
        ...params,
        product: localInventoryProductQuery.currentData?.localInventoryProduct,
      });
    }

    if (productQuery.currentData?.product) {
      return createFullProductFromQuery({ ...params, product: productQuery.currentData.product });
    }

    return null;
  }, [
    account,
    props.vehicleId,
    availabilityFilterEnhancements,
    showRetailPrice,
    localInventoryProductQuery.currentData?.localInventoryProduct,
    productQuery.currentData?.product,
  ]);

  useFailedProductTracking(data.isError, {
    partnumberid: props?.partNumberId ? props?.partNumberId : '',
    credentialId: props?.accountId ? Number(props.accountId) : undefined,
    linecard: props?.lineCardId ? Number(props.lineCardId) : undefined,
    part_term: props?.partTypeId ? Number(props.partTypeId) : undefined,
    vehicle: props?.vehicleId ? Number(props.vehicleId) : undefined,
    vin: props?.vin,
  });

  return useMemo(
    () => ({
      product,
      isLoading: data.isFetching,
      isSuccess: data.isSuccess,
      isFailed: data.isError,
      refetch: data.refetch,
    }),
    [data.isError, data.isFetching, data.isSuccess, data.refetch, product]
  );
};
