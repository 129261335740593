import { DateTime } from 'luxon';

export enum DateFormat {
  chart = 'yyyy-MM-dd', // 2019-09-20
  month = 'MMMM', // September
  time = 'hh:mm a', // 02:22 AM
  type1 = 'M/d/yyyy', // 9/20/2019
  type2 = 'LLLL d, yyyy', // September 20, 2019
  type3 = 'MMM d, y, hh:mm a', // Sep 20, 2019, 02:22 AM
  type4 = 'M/d/yy', // 9/20/19
  type5 = 'MMM d, y, hh:mm a ZZZZ', // Sep 20, 2019, 02:22 AM CST
  type6 = `LLLL d, yyyy, 'at' hh:mm a ZZZZ`, // September 20, 2019, at 02:22 AM CST
}

export const CURRENT_TIMEZONE = DateTime.now().toFormat('z'); // Europe/Kiev
export const CURRENT_YEAR = new Date().getFullYear(); // 2023
