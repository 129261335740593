import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { useToggleRetailPrice } from 'hooks/retailPricing';
import { useSearchPreferredBrands } from 'hooks/search';
// eslint-disable-next-line no-restricted-imports
import { useSearchParams } from 'hooks/search/useSearchParams';
// eslint-disable-next-line no-restricted-imports
import { useSearchEntryRequests } from 'hooks/searchResults/useSearchEntryRequests';
import { useSupplierAccounts } from 'hooks/supplierAccounts';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useAppSelector } from 'store';
import { selectCatalogSearchResults } from './useGetLazyCatalogProducts';
import { selectSearchResults } from './useGetLazyProducts';
import { selectLocalInventorySearchResults } from './useGetLocalInventoryProducts';

export const useProductResults = () => {
  const { getSearchEntryRequests, getCatalogSearchEntryRequest, getLocalInventorySearchEntryRequest } =
    useSearchEntryRequests();
  const { isTiresSearch, vehicle } = useSearchParams();
  const { preferredBrands } = useSearchPreferredBrands();

  const searchEntryRequests = useMemo(() => getSearchEntryRequests(), [getSearchEntryRequests]);
  const catalogSearchEntryRequest = useMemo(() => getCatalogSearchEntryRequest(), [getCatalogSearchEntryRequest]);
  const localInventorySearchEntryRequest = useMemo(
    () => getLocalInventorySearchEntryRequest(),
    [getLocalInventorySearchEntryRequest]
  );
  const { accounts } = useSupplierAccounts({
    type: isTiresSearch ? 'tires' : 'parts',
  });

  const { isShown: showRetailPrice } = useToggleRetailPrice();
  const {
    flags: { availabilityFilterEnhancements, useProductSortingLogicOnTheBackend: backendSortLogic },
  } = useLaunchDarkly();

  const data = useMemo(
    () => ({
      accounts,
      preferredBrands,
      vehicle,
      showRetailPrice,
      availabilityFilterEnhancements,
      backendSortLogic,
    }),
    [accounts, availabilityFilterEnhancements, backendSortLogic, preferredBrands, showRetailPrice, vehicle]
  );

  const results = useAppSelector((state) => selectSearchResults(state, searchEntryRequests, data), shallowEqual);
  const catalogResults = useAppSelector(
    (state) => selectCatalogSearchResults(state, catalogSearchEntryRequest, data),
    shallowEqual
  );
  const localInventoryResults = useAppSelector(
    (state) => selectLocalInventorySearchResults(state, localInventorySearchEntryRequest, data),
    shallowEqual
  );

  return {
    productResults: { ...results, ...catalogResults, ...localInventoryResults },
  };
};
