import { css } from '@emotion/react';
import { Card, Typography, TextCut, ImageFallback } from '@partstech/ui';
import { PreferredBrandLabel } from './PreferredBrandLabel';
import type { CardProps } from '@partstech/ui';
import type { Brand } from 'models';

const styles = {
  logo: css`
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  `,
};

type Props = {
  brand: Brand | null;
} & CardProps;

export const BrandLogo = ({ brand, ...cardProps }: Props) => {
  if (!brand) {
    return null;
  }

  const { isPreferred, logo, name } = brand;

  return (
    <>
      {isPreferred && <PreferredBrandLabel />}

      <Card
        background={logo ? 'white' : 'mono2'}
        borderColor="mono6"
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={2}
        {...cardProps}
        radius={isPreferred ? [0, 0, 2, 2] : 8}
      >
        {logo ? (
          <ImageFallback src={logo} alt={`${name} logo`} css={styles.logo} data-testid="brandLogo" />
        ) : (
          <Typography variant="button" color="mono40">
            <TextCut linesCount={1}>{name ?? 'No Image'}</TextCut>
          </Typography>
        )}
      </Card>
    </>
  );
};
