import { sortBy } from '@partstech/ui/utils';
import { createSelector } from '@reduxjs/toolkit';
import { createBaseProductFromQuery } from 'factories';
import { selectRootState } from 'store/utils';
import { createProductResult, generateSearchInput, isPreferredBrand } from 'utils';
import { api as generatedApi, useLazyGetProductsQuery } from './GetProducts.generated';
import { getSearchInputWithoutAttributes } from './getSearchInputWithoutAttributes';
import type { BaseProductFragment } from './BaseProductFragment.generated';
import type { QueryStatus } from '@reduxjs/toolkit/query';
import type { SupplierAccount, TireSize, Product, Vehicle } from 'models';
import type { SearchInput } from 'shared/api';
import type { PreferredBrandUnified } from 'types/preferredBrand';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['Product'],
  endpoints: {
    GetProducts: {
      providesTags: () => [{ type: 'Product' }],
      serializeQueryArgs: ({ queryArgs }) => {
        if (!queryArgs.searchInput) {
          return '';
        }

        return `Product(${JSON.stringify(getSearchInputWithoutAttributes(queryArgs.searchInput))})`;
      },
    },
  },
});

const emptyProducts: BaseProductFragment[] = [];

export const useGetLazyProducts = () => {
  const [trigger] = useLazyGetProductsQuery();

  return {
    getProducts: trigger,
  };
};

type SearchResultsParams = { accountId: string; searchInput: SearchInput }[];

type CreateProductParams = {
  accounts: SupplierAccount[];
  backendSortLogic?: boolean;
  filterEnhancements?: boolean;
  preferredBrands: PreferredBrandUnified[];
  showRetailPrice: boolean;
  vehicle: Vehicle | null;
};

export const selectSearchResults = createSelector(
  [selectRootState, (_, params: SearchResultsParams) => params, (_, __, data: CreateProductParams) => data],
  (rootState, params, data) =>
    params.reduce<Record<string, { status: QueryStatus; products: Product[]; errors?: string[] }>>(
      (acc, { accountId, searchInput }) => {
        const result = api.endpoints.GetProducts.select({ searchInput })(rootState);

        return {
          ...acc,
          [accountId]: createProductResult({
            status: result.status,
            products: (result.data?.products?.products ?? emptyProducts).map((product) => {
              const supplierAccount = data.accounts.find((account) => account.id === accountId);

              return createBaseProductFromQuery({
                account: supplierAccount,
                product,
                vehicleId: data.vehicle?.id,
                isPreferred: isPreferredBrand(data.preferredBrands)(
                  product.brand?.id ?? '',
                  product.brand?.name ?? '',
                  product.partType?.id ?? ''
                ),
                showRetailPrice: data.showRetailPrice,
                filterEnhancements: data.filterEnhancements,
              });
            }),
            errors: result.error?.message ? [result.error?.message ?? ''] : result.data?.products?.errors,
            backendSortLogic: data.backendSortLogic,
          }),
        };
      },
      {}
    )
);

type StockOrders = { id: string; supplierAccount: SupplierAccount | null; searchQueries: string[] };
type Params = { stockOrders: StockOrders[]; tiresSizeList: TireSize[]; filterEnhancements?: boolean };

export const selectStockSearchResults = createSelector(
  [selectRootState, (_: unknown, params: Params) => params],
  (rootState, { stockOrders, tiresSizeList, filterEnhancements }) =>
    stockOrders.reduce<
      Record<
        string,
        Record<string, { isLoading: boolean; isUninitialized: boolean; products: Product[]; errors?: string[] }>
      >
    >(
      (acc, { id, searchQueries, supplierAccount }) => ({
        ...acc,
        [id]: searchQueries.reduce<
          Record<string, { isLoading: boolean; isUninitialized: boolean; products: Product[]; errors?: string[] }>
        >((searchQueryAcc, searchQuery, index) => {
          const searchInput = generateSearchInput(
            searchQuery,
            Boolean(supplierAccount?.supplier?.isTire),
            String(supplierAccount?.id),
            tiresSizeList
          );
          const result = api.endpoints.GetProducts.select({
            searchInput,
          })(rootState);

          return {
            ...searchQueryAcc,
            [index]: {
              isLoading: result.status === 'pending',
              isUninitialized: result.status === 'uninitialized',
              products:
                sortBy(
                  result.data?.products?.products?.map((product) =>
                    createBaseProductFromQuery({
                      product,
                      account: supplierAccount,
                      filterEnhancements,
                    })
                  ) ?? [],
                  'sortHash'
                ) ?? [],
              errors: result.error?.message ? [result.error?.message ?? ''] : result.data?.products?.errors,
            },
          };
        }, {}),
      }),
      {}
    )
);
