import { css } from '@emotion/react';
import { Box, ImageFallback, ModalView, useMedia } from '@partstech/ui';
import { useKeyboardInteractions } from '@partstech/ui/hooks';
import { memo, useCallback, useMemo } from 'react';
import synchronizeIcon from 'images/icons/synchronize.svg';
import { useElementsNavigation } from 'shared/lib/react-dom';
import { FilesCarousel, Images360 } from 'shared/ui';
import { CarouselAction } from './CarouselAction';
import { ImageAction } from './ImageAction';
import type { MediaFile } from 'models';
import type { ProductImage } from 'shared/api';

const styles = {
  image: css`
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  `,
  video: css`
    border: 0;
    width: 100%;
    height: 100%;
  `,
};

export type FileViewerModalProps = {
  brandName: string;
  files: MediaFile[];
  image360Groups: ProductImage[][];
  partNumber: string;
  partTypeName: string;
  title?: string;
};

type Props = FileViewerModalProps & { onClose: () => void; currentIndex?: number };

export const FileViewerModal = memo(
  ({
    brandName,
    currentIndex,
    files,
    image360Groups,
    onClose,
    partNumber,
    partTypeName,
    title = 'View image',
  }: Props) => {
    const { isTablet, isMobile } = useMedia();
    const filesCount = image360Groups.length > 0 ? files.length + 1 : files.length;

    const { current, next, prev, currentClick } = useElementsNavigation(filesCount, currentIndex);

    const handleAdornmentClick = useCallback(() => {
      if (!isMobile) {
        currentClick?.(files.length);
      }
    }, [isMobile, currentClick, files.length]);

    useKeyboardInteractions({ onRightArrow: next, onLeftArrow: prev });

    const images360 = useMemo(
      () => image360Groups.flatMap((imageGroup) => imageGroup.map((image) => image.full ?? '')).reverse(),
      [image360Groups]
    );

    const currentFile = files[current];
    const previewFile = currentFile?.full;
    const isVideo = currentFile?.isVideo;
    const hasImages360Groups = image360Groups.length > 0;
    const shouldShowImageActions = files.length > 1 || hasImages360Groups;
    const alt = `${brandName} ${partNumber} ${partTypeName ?? ''} (full size)`;

    return (
      <ModalView title={title} closeIcon="close" onClose={onClose}>
        <Box
          display={{ sm: 'flex' }}
          flexDirection={{ sm: 'column', md: 'column', lg: 'row' }}
          my={{ md: 10, lg: 15 }}
          mx={{ md: 6, lg: 0 }}
          height={{ sm: `calc(100vh - 48px)`, md: '100%' }}
          alignContent={{ sm: 'center' }}
          justifyContent={{ sm: 'space-between' }}
        >
          <Box
            position="relative"
            maxWidth={{ lg: 200 }}
            width={{ lg: '100%' }}
            height={{ sm: '100%', md: 200 }}
            mx={{ sm: 6, lg: 'auto' }}
          >
            {isVideo && <iframe css={styles.video} allowFullScreen src={previewFile} title="video" />}

            {!isVideo && previewFile && <ImageFallback src={previewFile} alt={alt} css={styles.image} />}

            {!currentFile && hasImages360Groups && <Images360 images={images360} />}

            {shouldShowImageActions && (
              <>
                <ImageAction
                  iconName="chevron_left"
                  position="absolute"
                  variant="large"
                  top="50%"
                  left={{ sm: 2, md: -6, lg: -21 }}
                  radius={30}
                  onClick={prev}
                />
                <ImageAction
                  iconName="chevron_right"
                  position="absolute"
                  variant="large"
                  top="50%"
                  right={{ sm: 2, md: -6, lg: -21 }}
                  radius={30}
                  onClick={next}
                />
              </>
            )}
          </Box>

          <Box pb={{ sm: 8 }} px={{ sm: 4 }}>
            <FilesCarousel
              files={files}
              hasMediaControls={image360Groups.length > 0}
              currentIndex={current}
              isVertical={!isTablet && !isMobile}
              adornmentAfter={
                image360Groups.length > 0 && (
                  <CarouselAction
                    icon={synchronizeIcon}
                    title="View 360"
                    data-testid="view360"
                    isActive={current === files.length}
                    mt={!isTablet && !isMobile ? 2 : 0}
                    ml={!isTablet && !isMobile ? 0 : 2}
                    onClick={handleAdornmentClick}
                  />
                )
              }
              onClick={currentClick}
              mt={{ md: 7, lg: -13 }}
            />
          </Box>
        </Box>
      </ModalView>
    );
  }
);
