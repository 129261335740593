import { sortByHash } from './sortByHash';
import { sortBySponsored } from './sortBySponsored';
import type { QueryStatus } from '@reduxjs/toolkit/query';
import type { Product } from 'models';

type Props<T> = {
  status: QueryStatus;
  products: T[];
  errors?: string[];
  backendSortLogic?: boolean;
};

export const createProductResult = <T extends Product>({
  status,
  products,
  errors,
  backendSortLogic = false,
}: Props<T>): { status: QueryStatus; products: T[]; errors?: string[] } => ({
  status,
  products: backendSortLogic ? products : products.sort(sortByHash).sort(sortBySponsored),
  errors,
});
