import { RecentSearchType } from 'shared/api';
import { extractTireSizes } from '../utils/tire/extractTireSizes';
import { TireSize } from './TireSize';
import type { Vehicle } from './Vehicle';
import type {
  SearchLocalInventoryRequestConvertable,
  SearchEntryPointInterface,
  SearchQuery,
  SearchQueryConvertable,
  SearchRequestConvertable,
  ToRequestParams,
} from 'features/searchForm';
import type { CreateRecentSearchInput, LocalInventorySearchInput, SearchInput } from 'shared/api';

export class TireConfiguration
  implements
    SearchEntryPointInterface,
    SearchQueryConvertable,
    SearchRequestConvertable,
    SearchLocalInventoryRequestConvertable
{
  size: TireSize;

  rearSize: TireSize | null = null;

  constructor(size: TireSize, rearSize: TireSize | null = null) {
    this.size = size;

    if (rearSize) {
      this.rearSize = rearSize;
    }
  }

  public static createFromString(value: string) {
    const [frontSize, rearSize] = extractTireSizes(value);

    if (!frontSize) {
      return null;
    }

    return new TireConfiguration(
      TireSize.createFromValue(frontSize),
      rearSize ? TireSize.createFromValue(rearSize) : null
    );
  }

  isVehicleRequired(): boolean {
    return false;
  }

  getClassName(): string {
    return 'fs-tire';
  }

  getSizeByPosition(isRear: boolean) {
    return isRear ? this.rearSize : this.size;
  }

  isStaggered() {
    return this.rearSize ? this.rearSize.toString() !== this.size.toString() : false;
  }

  populateInput(): string {
    return this.toString();
  }

  toString() {
    return this.rearSize ? `FRONT: ${this.size} x REAR: ${this.rearSize}` : `${this.size}`;
  }

  toQuery(): SearchQuery {
    return {
      tire_sizes: this.rearSize ? `${this.size.toValue()},${this.rearSize.toValue()}` : this.size.toValue(),
    };
  }

  toValue() {
    return {
      size: this.size.toObject(),
      rearSize: this.rearSize?.toObject(),
    };
  }

  toRequest({ accountId, vehicleId }: ToRequestParams): SearchInput {
    if (!this.rearSize) {
      return {
        tireSize: {
          accountId,
          ...this.size.toObject(),
        },
      };
    }

    return {
      tireApplicationStaggered: {
        accountId,
        vehicleId,
        frontSize: this.size.toObject(),
        rearSize: this.rearSize.toObject(),
      },
    };
  }

  toLocalInventoryRequest(): LocalInventorySearchInput {
    if (!this.rearSize) {
      return {
        tireSize: this.size.toObject(),
      };
    }

    return {
      tireApplicationStaggered: {
        frontSize: this.size.toObject(),
        rearSize: this.rearSize.toObject(),
      },
    };
  }

  toRecentSearch(vehicle: Vehicle | null): CreateRecentSearchInput {
    return {
      type: RecentSearchType.Tires,
      tire: {
        name: this.toString(),
        ...this.toValue(),
      },
      vehicleID: vehicle?.id,
      state: vehicle?.state,
      plate: vehicle?.plate,
      vin: vehicle?.vin,
    };
  }
}
