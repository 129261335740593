import { entries, groupBy, uniqBy } from '@partstech/ui/utils';
import { MIN_RECENT_PURCHASE_QUANTITY_FOR_SHOWING } from 'constant';
import { captureException } from 'integrations/sentry';
import { findAttributeByName } from '../../utils/product/attributes/findAttributeByName';
import {
  getCustomAttributesForSection,
  makeProductAttribute,
} from '../../utils/product/attributes/getCustomAttributesForSection';
import { getCustomAttributesByNames } from '../../utils/tire/getCustomAttributesByNames';
import type { Brand } from '../Brand';
import type { Media } from './Media';
import type { PartNumber } from '../PartNumber';
import type { Quote } from '../Quote';
import type { Store } from '../Store';
import type { Supplier } from '../Supplier';
import type { AttributeName, AttributeUsageSection } from 'constant';
import type { Bundle, ProductDeliveryNote, ProductLink, ProductRebate } from 'shared/api';
import type { RewardPoints } from 'shared/api/rest/gen/shop';

type Params = Pick<
  Product,
  | 'attributes'
  | 'bundles'
  | 'coreCharge'
  | 'credentialId'
  | 'descriptions'
  | 'fetCharge'
  | 'hasVehicleFitment'
  | 'id'
  | 'interchangePart'
  | 'lineCardId'
  | 'listPrice'
  | 'mapPrice'
  | 'notes'
  | 'notesOem'
  | 'partTypeId'
  | 'partTypeName'
  | 'price'
  | 'quantityStep'
  | 'rebates'
  | 'retailPrice'
  | 'rewardPoints'
  | 'shippingCharge'
  | 'sortHash'
  | 'sponsoredPurchasesCount'
  | 'sponsorType'
  | 'stocked'
  | 'stockMessage'
  | 'title'
  | 'variations'
  | 'vehicleId'
> &
  Partial<
    Pick<
      Product,
      | 'attachments'
      | 'diagrams'
      | 'extendedInformation'
      | 'freightPackage'
      | 'interchanges'
      | 'links'
      | 'marketingDescriptions'
      | 'productAttributes'
      | 'specificMarketingDescriptions'
      | 'warnings'
      | 'localInventoryUID'
    >
  >;

export class Product {
  attributes: { name: string; values: string[] }[];

  attachments: {
    filename: string;
    group?: string | null;
    url: string;
  }[] = [];

  brand: Brand | null = null;

  bundles: {
    description?: string | null;
    expirationDate?: string | null;
    id: string;
    name: string;
  }[];

  coreCharge: number | null;

  credentialId: string | null;

  descriptions: {
    code: string;
    label: string;
    values: string[];
  }[];

  diagrams: { id: string; category?: { id: string } | null }[] | null = null;

  extendedInformation: { name: string; value: string }[] | null = null;

  fetCharge: number | null;

  freightPackage: {
    merchandising?: {
      height: number;
      length: number;
      width: number;
    } | null;
    shipping?: {
      height: number;
      length: number;
      width: number;
    } | null;
    weight?: number | null;
  } | null = null;

  hasVehicleFitment: boolean;

  id: string;

  interchangePart: { brand: string | null; partNumber: string; type: 'INTCH' | 'SUPERSEDE' | 'ALTERNATE' } | null;

  interchanges:
    | {
        brandName?: string | null;
        partNumber: string;
        type: 'ALTERNATE' | 'INTERCHANGE' | 'SIBLING' | 'SUPERSEDE';
      }[]
    | null = null;

  lineCardId: number | null;

  listPrice: number | null;

  localInventoryUID?: string | null;

  mapPrice: number | null;

  marketingDescriptions: string[] | null = null;

  media: Media | null = null;

  notes: string[][];

  notesOem: string[];

  partNumber: PartNumber | null = null;

  partTypeId: string | null;

  partTypeName: string | null;

  price: number | null;

  quantityStep: number;

  quote: Quote | null = null;

  rebates: ProductRebate[];

  retailPrice: number | null;

  rewardPoints: RewardPoints | null;

  showRetailPrice: boolean | null = null;

  sortHash: string;

  sponsorType: 'NONE' | 'SPONSORED' | 'POTENTIALLY_SPONSORED' = 'NONE';

  sponsoredPurchasesCount: number | null = null;

  stockMessage: ProductDeliveryNote[] | null;

  shippingCharge: boolean;

  stocked: boolean;

  store: Store | null = null;

  supplier: Supplier | null = null;

  specificMarketingDescriptions: {
    code: string;
    label: string;
    values: string[];
  }[] = [];

  productAttributes: { name: string; values: string[] }[] = [];

  title: string;

  variations: {
    attributes: {
      name: string;
      values: string[];
    }[];
    notes: { group: string[] }[];
    variationID: string;
  }[];

  vehicleId: number | null;

  links: ProductLink[] = [];

  warnings: { url?: string; text?: string }[] = [];

  constructor(product: Params) {
    this.attachments = product.attachments ?? [];
    this.attributes = product.attributes;
    this.bundles = product.bundles ?? [];
    this.coreCharge = product.coreCharge;
    this.credentialId = product.credentialId;
    this.descriptions = product.descriptions;
    this.diagrams = product.diagrams ?? [];
    this.extendedInformation = product.extendedInformation ?? [];
    this.fetCharge = product.fetCharge;
    this.freightPackage = product.freightPackage ?? null;
    this.hasVehicleFitment = product.hasVehicleFitment;
    this.id = product.id;
    this.interchangePart = product.interchangePart;
    this.interchanges = product.interchanges ?? [];
    this.lineCardId = product.lineCardId;
    this.links = product.links ?? [];
    this.listPrice = product.listPrice;
    this.localInventoryUID = product.localInventoryUID;
    this.mapPrice = product.mapPrice;
    this.marketingDescriptions = product.marketingDescriptions ?? [];
    this.notes = product.notes;
    this.notesOem = product.notesOem;
    this.partTypeId = product.partTypeId;
    this.partTypeName = product.partTypeName;
    this.price = product.price;
    this.productAttributes = product.productAttributes ?? [];
    this.quantityStep = product.quantityStep;
    this.rebates = product.rebates ?? [];
    this.retailPrice = product.retailPrice;
    this.rewardPoints = product.rewardPoints;
    this.shippingCharge = product.shippingCharge ?? false;
    this.sortHash = product.sortHash;
    this.specificMarketingDescriptions = product.specificMarketingDescriptions ?? [];
    this.sponsoredPurchasesCount = product.sponsoredPurchasesCount ?? null;
    this.sponsorType = product.sponsorType;
    this.stocked = product.stocked;
    this.stockMessage = product.stockMessage;
    this.title = product.title;
    this.variations = product.variations;
    this.vehicleId = product.vehicleId;
    this.warnings = product.warnings ?? [];
  }

  get isBundleAvailable(): boolean {
    return this.bundles.length > 0;
  }

  get isStaggeredTire(): boolean {
    return this.isTire && this.hasPosition;
  }

  get isTire(): boolean {
    return this.supplier?.isTire ?? false;
  }

  get hasPosition(): boolean {
    return Boolean(findAttributeByName('Position', this.attributes));
  }

  get points(): number | null {
    return this.rewardPoints?.points ?? null;
  }

  get shouldApplyFetCharge(): boolean {
    return !this.supplier?.isTireDisconnected && this.isTire && Boolean(this.fetCharge === null || this.fetCharge > 0);
  }

  canBuy() {
    return this.quote !== null && this.quote.isAddToCart();
  }

  getActualPrice() {
    if (this.showRetailPrice) {
      return this.retailPrice || this.price;
    }

    return this.price;
  }

  getAttachments() {
    return entries(groupBy(this.attachments, 'group'))
      .map(([name, items]) => ({
        name,
        files: items.map((item) => ({
          filename: item.filename,
          full: item.url,
        })),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  getAttributeByName(name: AttributeName) {
    return this.getAttributesByNames([name])[0] ?? null;
  }

  getAttributesByNames(names: AttributeName[]) {
    return getCustomAttributesByNames(names, this.attributes.map(makeProductAttribute));
  }

  getAttributesForSection(section: AttributeUsageSection, variationId: string | null = null) {
    const variation = this.getVariationById(variationId);

    return getCustomAttributesForSection([...this.attributes, ...(variation?.attributes ?? [])], section);
  }

  getCoreCharge() {
    if (this.showRetailPrice) {
      return null;
    }

    return this.coreCharge;
  }

  getDefaultQuantity(variationId: string | null = null): number {
    if (this.quote && this.quantityStep > 1) {
      return this.quantityStep;
    }

    if (this.isTire) {
      const availableQuantity = this.quote?.getAvailableQuantity();

      if (this.isStaggeredTire) {
        if (availableQuantity && availableQuantity < 2) {
          return availableQuantity;
        }

        return 2;
      }

      if (availableQuantity && availableQuantity < 4) {
        return availableQuantity;
      }

      return 4;
    }

    const attributes = this.getAttributesForSection('product', variationId);
    const quantityPerVehicle = findAttributeByName('Qty', attributes);

    return quantityPerVehicle ? Number(quantityPerVehicle.value) : 1;
  }

  getDiagramId() {
    if (this.diagrams?.length) {
      return this.diagrams[0]?.id ?? null;
    }

    return null;
  }

  getDiagramCategoryId() {
    if (this.diagrams?.length) {
      return this.diagrams[0]?.category?.id ?? null;
    }

    return null;
  }

  getDescriptionByCode(code: string) {
    return this.descriptions.find((description) => description.code === code);
  }

  getSpecificMarketingDescriptionByCode(code: string) {
    return this.specificMarketingDescriptions.find((description) => description.code === code);
  }

  getProductAttributeByName(name: string) {
    return this.productAttributes.find((attribute) => attribute.name === name);
  }

  getFetCharge() {
    return this.fetCharge;
  }

  getId() {
    try {
      return [this.getPartNumberId().replace(/[^\w]/g, ''), this.credentialId, this.lineCardId, this.supplier?.id].join(
        '-'
      );
    } catch (error) {
      captureException(error, {
        data: {
          partNumberId: this.getPartNumberId(),
          typeOfPartNumberId: typeof this.getPartNumberId(),
          productId: this.id,
        },
      });

      return '';
    }
  }

  getPartNumberId() {
    return this.partNumber?.id ?? '';
  }

  getListPrice() {
    if (this.showRetailPrice || this.isTire) {
      return null;
    }

    return this.listPrice;
  }

  getLoadIndex() {
    return Number(this.getAttributeByName('TiresLoadIndex')?.value);
  }

  getMapPrice() {
    if (this.showRetailPrice) {
      return null;
    }

    return this.mapPrice;
  }

  getNotes(variationId: string | null = null) {
    const variation = this.getVariationById(variationId);

    const notes = this.notes.flatMap((group) => {
      const filteredNotes = group.filter((note) => note.trim() !== '');
      return filteredNotes.length > 0 ? [filteredNotes] : [];
    });

    return variation ? [...notes, ...variation.notes.map((note) => note.group)] : notes;
  }

  getRetailPrice() {
    if (this.showRetailPrice) {
      return null;
    }

    return this.retailPrice || this.price;
  }

  getSpeedRating() {
    return this.getAttributeByName('TiresSpeedRating')?.value;
  }

  getTitle() {
    return this.title;
  }

  static getUniqueBundles(bundles?: Bundle[]) {
    return bundles ? uniqBy(bundles, (bundle) => bundle.name.toLowerCase()) : null;
  }

  getVariationAttributes(section: AttributeUsageSection, variationId: string) {
    const variation = this.getVariationById(variationId);

    return getCustomAttributesForSection(variation?.attributes ?? [], section);
  }

  getVariationById(id: string | null) {
    return this.variations?.find((item) => item.variationID === id) || null;
  }

  getRecentPurchasesCount() {
    if (this.sponsoredPurchasesCount && this.sponsoredPurchasesCount <= MIN_RECENT_PURCHASE_QUANTITY_FOR_SHOWING) {
      return null;
    }

    return this.sponsoredPurchasesCount;
  }

  hasDiagrams() {
    if (!this.diagrams) {
      return false;
    }

    return this.diagrams.length > 0;
  }

  hasEnoughQuantity(quantity: number): boolean {
    if (!this.quote) {
      return false;
    }
    return this.quote?.getAvailableQuantity() >= quantity;
  }

  isPotentiallySponsored() {
    return this.sponsorType === 'POTENTIALLY_SPONSORED';
  }

  hasQuantityExceedingSelectedStore(quantity: number) {
    const shouldDistributeQuantities = this.supplier?.shouldDistributeQuantities;
    const canSelectStore = this.supplier?.canSelectPartStore;
    const hasOneLine = this.quote?.hasOneLine();
    const store = this.quote?.lines[0];

    if (!store || shouldDistributeQuantities || canSelectStore || hasOneLine) {
      return false;
    }

    if (this.getDefaultQuantity() === quantity && this.getDefaultQuantity() > store.quantity) {
      return false;
    }

    return store.quantity < quantity;
  }

  isRear() {
    return this.isTire && this.getAttributeByName('Position')?.value === 'Rear';
  }

  isSponsored() {
    return this.sponsorType === 'SPONSORED';
  }

  shouldCallForAvailability() {
    if (this.canBuy() || !this.store || !this.store.phone) {
      return false;
    }

    return !this.quote?.isCallAvailability() || !this.quote?.isCallSupplier();
  }

  setBrand(brand: Brand) {
    this.brand = brand;
  }

  setMedia(media: Media) {
    this.media = media;
  }

  setPartNumber(partNumber: PartNumber) {
    this.partNumber = partNumber;
  }

  setQuote(quote: Quote | null) {
    this.quote = quote;
  }

  setShowRetailPrice(isShowRetailPrice: boolean) {
    this.showRetailPrice = isShowRetailPrice;
  }

  setStore(store: Store) {
    this.store = store;
  }

  setSupplier(supplier: Supplier) {
    this.supplier = supplier;
  }
}
