/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../../../shared/api/graphql/graphql';

export type LocalInventoryProductFragment = {
  __typename?: 'LocalInventoryProduct';
  localInventoryUID: string;
  customerPrice?: Types.Scalars['BigDecimal']['input'] | null;
  hasFitment: Types.Scalars['Boolean']['input'];
  listPrice?: Types.Scalars['BigDecimal']['input'] | null;
  quantity: number;
  partNumber: Types.Scalars['String']['input'];
  partNumberId: Types.Scalars['String']['input'];
  price?: Types.Scalars['BigDecimal']['input'] | null;
  sortHash?: Types.Scalars['String']['input'] | null;
  title: Types.Scalars['String']['input'];
  attributes: Array<{
    __typename?: 'LocalInventoryProductAttribute';
    name: Types.Scalars['String']['input'];
    values: Array<Types.Scalars['String']['input']>;
  }>;
  brand?: {
    __typename?: 'Brand';
    id: string;
    lineCode?: Types.Scalars['String']['input'] | null;
    logo?: Types.Scalars['String']['input'] | null;
    name: Types.Scalars['String']['input'];
  } | null;
  descriptions: Array<{
    __typename?: 'LocalInventoryProductDescription';
    code: Types.Scalars['String']['input'];
    label: Types.Scalars['String']['input'];
    values: Array<Types.Scalars['String']['input']>;
  }>;
  images: Array<{
    __typename?: 'LocalInventoryProductImage';
    full: Types.Scalars['String']['input'];
    medium: Types.Scalars['String']['input'];
    preview: Types.Scalars['String']['input'];
  }>;
  interchangeFor?: {
    __typename?: 'LocalInventoryProductInterchange';
    brandName?: Types.Scalars['String']['input'] | null;
    partNumber: Types.Scalars['String']['input'];
    type: Types.LocalInventoryProductInterchangeType;
  } | null;
  image360Groups: Array<{
    __typename?: 'LocalInventoryProductImage360Group';
    images: Array<{
      __typename?: 'LocalInventoryProductImage';
      full: Types.Scalars['String']['input'];
      preview: Types.Scalars['String']['input'];
      medium: Types.Scalars['String']['input'];
    }>;
  }>;
  notes: Array<{ __typename?: 'LocalInventoryProductNotes'; group: Array<Types.Scalars['String']['input']> }>;
  partType?: { __typename?: 'PartType'; id: string; name: Types.Scalars['String']['input'] } | null;
  variations: Array<{
    __typename?: 'LocalInventoryProductVariation';
    variationID: Types.Scalars['String']['input'];
    attributes: Array<{
      __typename?: 'LocalInventoryProductAttribute';
      name: Types.Scalars['String']['input'];
      values: Array<Types.Scalars['String']['input']>;
    }>;
    notes: Array<{ __typename?: 'LocalInventoryProductNotes'; group: Array<Types.Scalars['String']['input']> }>;
  }>;
  videos: Array<{
    __typename?: 'LocalInventoryProductVideo';
    full: Types.Scalars['String']['input'];
    medium: Types.Scalars['String']['input'];
    preview: Types.Scalars['String']['input'];
  }>;
};

export const LocalInventoryProductFragmentDoc = `
    fragment LocalInventoryProduct on LocalInventoryProduct {
  localInventoryUID
  attributes {
    name
    values
  }
  brand {
    id
    lineCode
    logo
    name
  }
  customerPrice
  descriptions {
    code
    label
    values
  }
  hasFitment
  images {
    full
    medium
    preview
  }
  interchangeFor {
    brandName
    partNumber
    type
  }
  image360Groups {
    images {
      full
      preview
      medium
    }
  }
  listPrice
  quantity
  notes {
    group
  }
  partNumber
  partNumberId
  partType {
    id
    name
  }
  price
  sortHash
  title
  variations {
    attributes {
      name
      values
    }
    notes {
      group
    }
    variationID
  }
  videos {
    full
    medium
    preview
  }
}
    `;
