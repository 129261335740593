import { LocalInventoryAvailabilityLine, Quote } from 'models';
import type { LocalInventoryProductFragment } from 'store/queries/searchResults/LocalInventoryProductFragment.generated';

type Params = {
  product: LocalInventoryProductFragment;
};
export const createLocalInventoryQuoteFromQuery = ({ product }: Params) =>
  new Quote({
    action: 'HIDDEN',
    status: 'LOCAL_INVENTORY',
    lines: [
      new LocalInventoryAvailabilityLine({
        address: null,
        group: null,
        id: null,
        quantity: product.quantity,
        name: '',
        type: 'MAIN',
      }),
    ],
    variant: 'default',
  });
