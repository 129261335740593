import { LocalInventoryPartNumberType, RecentSearchType } from 'shared/api';
import { SearchTypes } from 'types/search';
import type { Vehicle } from './Vehicle';
import type {
  PartNumberQuery,
  SearchCatalogRequestConvertable,
  SearchLocalInventoryRequestConvertable,
  SearchEntryPointInterface,
  SearchQueryConvertable,
  SearchRequestConvertable,
  ToCatalogRequestParams,
  ToRequestParams,
  ToLocalInventoryResourcesParams,
} from 'features/searchForm';
import type { CatalogSearchInput, CreateRecentSearchInput, LocalInventorySearchInput, SearchInput } from 'shared/api';

type PartNumberData = {
  id: string;
  partNumber: string;
  brandName?: string | null;
  searchType?: SearchTypes;
  columnTitle?: string;
};

export class PartNumber
  implements
    SearchEntryPointInterface,
    SearchQueryConvertable,
    SearchRequestConvertable,
    SearchCatalogRequestConvertable,
    SearchLocalInventoryRequestConvertable
{
  partNumber: string;

  id: string;

  brandName: string | null;

  searchType: SearchTypes = SearchTypes.PARTS;

  columnTitle = 'part numbers';

  constructor({ partNumber, id, brandName, searchType, columnTitle }: PartNumberData) {
    this.brandName = brandName ?? null;
    this.id = id;
    this.partNumber = partNumber;

    if (searchType) {
      this.searchType = searchType;
    }

    if (columnTitle) {
      this.columnTitle = columnTitle;
    }
  }

  isVehicleRequired(): boolean {
    return false;
  }

  getClassName(): string {
    return 'fs-partnumber';
  }

  getColumnTitle(): string {
    return this.columnTitle;
  }

  populateInput(): string {
    return this.partNumber;
  }

  toString(): string {
    return this.brandName ? `${this.partNumber} - ${this.brandName}` : `${this.partNumber} - All Brands`;
  }

  toQuery(): PartNumberQuery {
    if (this.searchType === SearchTypes.TIRES) {
      return {
        type: SearchTypes.TIRES,
        partnumber: this.partNumber,
      };
    }

    return {
      type: SearchTypes.PARTS,
      partnumber: this.partNumber,
      manufacturers: this.brandName ? [this.brandName] : undefined,
    };
  }

  toRequest({ accountId, vehicleId }: ToRequestParams): SearchInput {
    return {
      partNumber: {
        accountId,
        partNumber: this.partNumber,
        vehicleId,
      },
    };
  }

  toCatalogRequest({ vehicleId }: ToCatalogRequestParams): CatalogSearchInput {
    return {
      partNumber: {
        partNumber: this.partNumber,
        vehicleId,
      },
    };
  }

  toLocalInventoryRequest({ vehicleId }: ToLocalInventoryResourcesParams): LocalInventorySearchInput {
    return {
      partNumber: {
        type:
          this.searchType === SearchTypes.TIRES
            ? LocalInventoryPartNumberType.Tires
            : LocalInventoryPartNumberType.Parts,
        partNumber: this.partNumber,
        vehicleId,
      },
    };
  }

  toRecentSearch(vehicle: Vehicle | null): CreateRecentSearchInput {
    return {
      type: this.searchType === SearchTypes.TIRES ? RecentSearchType.Tires : RecentSearchType.PartNumber,
      partNumber: this.partNumber,
      manufacturers: this.brandName ? [this.brandName] : undefined,
      vehicleID: vehicle?.id,
      state: vehicle?.state,
      plate: vehicle?.plate,
      vin: vehicle?.vin,
    };
  }
}
