import { Box, GraphicTab } from '@partstech/ui';
import type { GraphicTabProps } from '@partstech/ui';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  adornmentBefore: GraphicTabProps<string>['adornmentBefore'];
  id: string;
  isDisabled?: boolean;
  isLoading: boolean;
  isSticky: boolean;
  total: number;
}>;

export const Tab = ({ adornmentBefore, id, children, isDisabled, isLoading, isSticky, total, ...props }: Props) => (
  <GraphicTab
    id={id}
    adornmentBefore={<Box pr={isSticky ? 6 : 0}>{adornmentBefore}</Box>}
    badge={{
      content: total,
      showZero: false,
      backgroundColor: 'lightBlue',
      borderColor: 'primary',
      color: 'primary',
      offsetX: isSticky ? -2 : 0,
      offsetY: isSticky ? 1.5 : 0,
    }}
    isDisabled={isDisabled}
    isLoading={isLoading}
    variant={isSticky ? 'sticky' : 'default'}
    size={isSticky ? 'sticky' : 'small'}
    {...props}
  >
    {children}
  </GraphicTab>
);
