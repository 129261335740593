import { AvailabilityTooltip } from 'constant';
import { Availability } from './Availability';
import { InStockAvailability } from './InStockAvailability';
import { AvailableOrderStatus } from './Statuses/AvailableOrderStatus';
import { BackorderStatus } from './Statuses/BackorderStatus';
import { LocalInventoryStatus } from './Statuses/LocalInventoryStatus';
import { OutOfStockStatus } from './Statuses/OutOfStockStatus';
import { SpecialOrderStatus } from './Statuses/SpecialOrderStatus';
import type { AvailabilityLine, Quote } from 'models';
import type { AvailabilityLineVariant } from 'types/product';

type Props = {
  allowSelect: boolean | undefined;
  onStoreChange?: (store: AvailabilityLine) => void;
  quote: Quote | null;
  store?: AvailabilityLine;
  usePortal?: boolean;
  disableAutoPlacement?: boolean;
  variant?: AvailabilityLineVariant;
};

export const StoreAvailability = ({
  allowSelect = false,
  onStoreChange,
  quote,
  store,
  usePortal,
  disableAutoPlacement,
  variant = 'default',
}: Props) => {
  if (!quote) {
    return null;
  }

  switch (true) {
    case quote.isLocalInventory():
      return (
        <Availability
          mt={1}
          allowSelect={allowSelect}
          store={store}
          onStoreChange={onStoreChange}
          status={LocalInventoryStatus}
          usePortal={usePortal}
          disableAutoPlacement={disableAutoPlacement}
          variant={variant}
        />
      );

    case quote.isAvailableOrder():
      return (
        <Availability
          mt={1}
          allowSelect={allowSelect}
          onStoreChange={onStoreChange}
          status={AvailableOrderStatus}
          usePortal={usePortal}
          disableAutoPlacement={disableAutoPlacement}
          variant={variant}
        />
      );

    case quote.isInStock():
      return (
        <InStockAvailability
          allowSelect={allowSelect}
          quote={quote}
          store={store}
          onStoreChange={onStoreChange}
          usePortal={usePortal}
          disableAutoPlacement={disableAutoPlacement}
          variant={variant}
        />
      );

    case quote.isBackOrder():
      return (
        <Availability
          mt={1}
          allowSelect={allowSelect}
          onStoreChange={onStoreChange}
          status={BackorderStatus}
          store={store}
          message={AvailabilityTooltip.BACK_ORDER}
          usePortal={usePortal}
          disableAutoPlacement={disableAutoPlacement}
          variant={variant}
        />
      );

    case quote.isSpecialOrder():
      return (
        <Availability
          mt={1}
          allowSelect={allowSelect}
          onStoreChange={onStoreChange}
          status={SpecialOrderStatus}
          store={store}
          message={AvailabilityTooltip.SPECIAL_ORDER}
          usePortal={usePortal}
          disableAutoPlacement={disableAutoPlacement}
          variant={variant}
        />
      );

    case quote.isOutOfStock():
      return (
        <Availability
          mt={1}
          allowSelect={allowSelect}
          onStoreChange={onStoreChange}
          status={OutOfStockStatus}
          store={store}
          usePortal={usePortal}
          disableAutoPlacement={disableAutoPlacement}
          variant={variant}
        />
      );

    default:
      return null;
  }
};
