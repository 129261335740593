import { getOnlyNumberWithoutZero } from '../utils/search/getOnlyNumberWithoutZero';
import type {
  SearchEntryPointInterface,
  SearchQueryConvertable,
  SearchQuery,
  SearchRequestConvertable,
  ToRequestParams,
  SearchLocalInventoryRequestConvertable,
} from 'features/searchForm';
import type { TireSize as TireSizeType, SearchInput, LocalInventorySearchInput } from 'shared/api';

export class TireSize
  implements
    SearchEntryPointInterface,
    SearchQueryConvertable,
    SearchRequestConvertable,
    SearchLocalInventoryRequestConvertable
{
  width: number;

  height: number;

  rim: number;

  loadIndex: number | null;

  speedRating: string | null;

  constructor(size: TireSizeType) {
    this.width = size.width ?? 0;
    this.height = size.height ?? 0;
    this.rim = size.rim ?? 0;
    this.loadIndex = size.loadIndex ?? null;
    this.speedRating = size.speedRating ?? null;
  }

  get id(): string {
    return this.toValue();
  }

  get name(): string {
    return this.toString();
  }

  getColumnTitle() {
    return 'sizes';
  }

  toPlainText(): string {
    return `${this.width}${this.height}${this.rim}`;
  }

  isEqual(tireSize: TireSize) {
    return this.width === tireSize.width && this.height === tireSize.height && this.rim === tireSize.rim;
  }

  isMatch(search: string): boolean {
    const searchType = TireSize.isCommercial(search) ? search : search.replace(/[^0-9,.]/g, '');
    const formatedSearch = getOnlyNumberWithoutZero(searchType);

    return getOnlyNumberWithoutZero(this.toPlainText()) === formatedSearch;
  }

  toValue(): string {
    return `${this.width}-${this.height}-${this.rim}`;
  }

  toString(rimSymbol: 'R' | '/' = 'R'): string {
    return `${this.width}/${this.height}${rimSymbol}${this.rim}`;
  }

  toObject() {
    return {
      width: this.width,
      height: this.height,
      rim: this.rim,
    };
  }

  static isCommercial(size: string) {
    return size.match(/^\d+R\d{2}(\.\d+)?$/) !== null;
  }

  static createFromValue(value: string): TireSize {
    const [width, height, rim] = value.split('-');

    return new TireSize({
      width: Number(width),
      height: Number(height),
      rim: Number(rim),
    });
  }

  static createFromString(tireSize?: string) {
    if (!tireSize) {
      return null;
    }

    const [size] = tireSize ? tireSize.split(' ') : [];
    const [rawWidth, rest] = size ? size.split('/') : [];
    const [rawHeight, rawRim] = rest ? rest.split('R') : [];
    const [width, height, rim] = [rawWidth, rawHeight, rawRim].map(
      (param) => param && Number(param.replace(/[^0-9.]/g, ''))
    );

    if (width && height && rim) {
      return new TireSize({ width, height, rim });
    }

    return null;
  }

  isVehicleRequired(): boolean {
    return false;
  }

  populateInput(): string {
    return this.toString();
  }

  toQuery(): SearchQuery {
    return {
      tire_sizes: this.toValue(),
    };
  }

  toRequest({ accountId }: ToRequestParams): SearchInput {
    return {
      tireSize: {
        accountId,
        width: this.width,
        height: this.height,
        rim: this.rim,
      },
    };
  }

  toLocalInventoryRequest(): LocalInventorySearchInput {
    return {
      tireSize: {
        width: this.width,
        height: this.height,
        rim: this.rim,
      },
    };
  }
}
