import { css } from '@emotion/react';
import { Box, ImageFallback, Link, Typography, useMedia } from '@partstech/ui';
import { useOpen } from '@partstech/ui/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSettings } from 'hooks/app';
import { Images360 } from 'shared/ui';
import { Assets } from './Assets';
import { DiagramsButton } from './DiagramsButton';
import { FilesCarousel } from './FilesCarousel';
import { FileViewerNavigation } from './FileViewerNavigation';
import type { Theme } from '@partstech/ui';
import type { Product } from 'models';
import type { MouseEvent } from 'react';

const styles = {
  video: css`
    border: 0;
    width: 100%;
    height: 100%;

    @media print {
      display: none;
    }
  `,

  logo: (shownCarusel: boolean, shownDiagramButton: boolean) => (theme: Theme) => css`
    max-height: ${theme.sizing(6)};
    max-width: 100%;
    z-index: ${theme.zIndex.default};

    ${shownCarusel &&
    `position: absolute;
    top: ${theme.sizing(shownDiagramButton ? 34 : 18)};
    right: 0;`}
  `,
  imageSlide: (theme: Theme) => css`
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    max-width: 100%;
    max-height: ${theme.sizing(79)};
  `,
  imageWrap: css`
    > div:first-of-type {
      width: 100%;
      height: 100%;
    }
  `,
};

type Props = {
  product: Product;
  defaultCurrent?: number;
  isWindow?: boolean;
  hideCarousel?: boolean;
  useAssets?: boolean;
  useBigImages?: boolean;
  useDiagrams?: boolean;
  isShowBrandLogo?: boolean;
  onAssetsClick?: () => void;
  onImageClick?: (imageIndex: number) => void;
};

export const FileViewer = ({
  product,
  defaultCurrent = 0,
  isWindow = false,
  hideCarousel = false,
  useAssets = true,
  useBigImages = false,
  useDiagrams = true,
  isShowBrandLogo = false,
  onAssetsClick = () => {},
  onImageClick = () => {},
}: Props) => {
  const { isMobile, isPrint } = useMedia();

  const { shopDomain } = useSettings();

  const [current, setCurrent] = useState(defaultCurrent);

  const { isOpen: isMouseOver, open: handleMouseOver, close: handleMouseOut } = useOpen();

  const files = useMemo(() => product.media?.extractFiles() ?? [], [product]);
  const images360 = useMemo(() => product.media?.extract360Images() ?? [], [product]);
  const attachments = useMemo(() => product.getAttachments(), [product]);

  const currentFile = files[current];
  const hasImages360Groups = product.media?.hasImages360Groups();
  const isVideo = currentFile?.isVideo;

  useEffect(
    () => () => {
      setCurrent(0);
    },
    [product]
  );

  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();

      onImageClick(current);
    },
    [current, onImageClick]
  );

  const handleCarouselFileClick = useCallback(
    (currentIndex: number, isView360?: boolean) => {
      if (isView360 && !isMobile) {
        onImageClick?.(currentIndex);
        return;
      }

      setCurrent(currentIndex);
    },
    [isMobile, onImageClick]
  );

  const alt = `${product.brand?.name} ${product.partNumber?.partNumber ?? ''} ${product.partTypeName ?? ''}${
    useBigImages && ' (full size)'
  }`;

  const shownDiagramButton = useDiagrams && !isPrint;
  const src = useBigImages ? (currentFile?.full ?? '') : currentFile?.medium || currentFile?.full || '';

  return (
    <Box position="relative" onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut} data-testid="fileViewer">
      {shownDiagramButton && (
        <DiagramsButton
          vehicleId={product.vehicleId}
          diagramCategoryId={product.getDiagramCategoryId()}
          diagramId={product.getDiagramId()}
        />
      )}

      {isShowBrandLogo &&
        (product.brand?.logo ? (
          <ImageFallback
            alt={`${product.brand.name} logo`}
            title={`${shopDomain} profile page for ${product.brand.name}`}
            src={product.brand.logo ?? ''}
            css={styles.logo(!hideCarousel, shownDiagramButton)}
          />
        ) : (
          <Typography variant="caption">{product.brand?.name}</Typography>
        ))}

      {!isPrint && !hideCarousel && (files.length > 1 || hasImages360Groups) && (
        <FilesCarousel
          files={files}
          has360Images={hasImages360Groups}
          currentIndex={current}
          onClick={handleCarouselFileClick}
          alt={alt}
        />
      )}

      <Box
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height={79}
        css={styles.imageWrap}
      >
        {isVideo && <iframe css={styles.video} allowFullScreen src={currentFile?.full} title="video" />}

        {!isVideo && currentFile && (
          <Link fill to="#" onClick={isWindow ? handleClick : undefined}>
            <ImageFallback
              css={styles.imageSlide}
              alt={`${alt} #${current + 1}`}
              src={src}
              data-testid="currentImage"
            />
          </Link>
        )}
        {!currentFile && hasImages360Groups && <Images360 images={images360} />}

        {!isPrint && (isMobile || isMouseOver) && files.length > 1 && (
          <FileViewerNavigation
            currentIndex={current}
            setCurrentIndex={setCurrent}
            filesCount={hasImages360Groups && isMobile ? files.length + 1 : files.length}
          />
        )}
      </Box>

      {useAssets && attachments && attachments.length > 0 && (
        <Box mt={5}>
          <Assets attachments={attachments} onAssetsClick={onAssetsClick} />
        </Box>
      )}
    </Box>
  );
};
