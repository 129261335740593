import { css } from '@emotion/react';
import { ImageFallback } from '@partstech/ui';
import { memo, useCallback } from 'react';
import { useImages360Navigation } from './useImages360Navigation';

const styles = {
  button: (isDragging: boolean) => css`
    cursor: ${isDragging ? 'grabbing' : 'grab'};
    padding: 0;
    flex: auto;
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
  `,
  image: css`
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  `,
};

type Props = {
  images: string[];
};

export const Images360 = memo(({ images }: Props) => {
  const { currentIndex, isDragging, onMouseDown } = useImages360Navigation(images);

  const handlePreventDrag = useCallback((e: React.DragEvent) => {
    e.preventDefault();
  }, []);

  return (
    <button
      type="button"
      onMouseDown={onMouseDown}
      onDragStart={handlePreventDrag}
      onTouchStart={onMouseDown}
      css={styles.button(isDragging)}
    >
      <ImageFallback src={images[currentIndex]} alt="360 view" css={styles.image} />
    </button>
  );
});
