import { css } from '@emotion/react';
import { Box, Button, Checkbox, ModalButtons, Typography } from '@partstech/ui';
import { useOpen } from '@partstech/ui/hooks';
import { useSubscription } from 'features/subscription';
import { getFormattedDateFromISO } from 'shared/lib/dates';
import { SupportContact } from 'shared/ui';
import type { Theme } from '@partstech/ui';
import type { SupplierAccount } from 'models';

const styles = {
  modalButton: (theme: Theme) => css`
    padding: ${theme.sizing(2)};
  `,
};

type Props = {
  account: SupplierAccount;
  isSubmitting: boolean;
  onCancel: () => void;
  onSubmit: () => void;
};

export const DeletePaidCredentials = ({ account, isSubmitting, onSubmit, onCancel }: Props) => {
  const { store, supplier } = account;

  const { isOpen: isTermsAccepted, toggle: toggleTerms } = useOpen();

  const { subscription } = useSubscription();
  const currentPeriodEnd = subscription?.currentPeriodEnd;

  if (!supplier || !store) {
    return null;
  }

  return (
    <>
      <Box textAlign="center" m={5}>
        <Typography variant="h3">Are you sure you want to remove {supplier.name}?</Typography>
      </Box>

      <SupportContact
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent={{ sm: 'center', md: 'space-between' }}
        alignItems="center"
        px={{ sm: 2, md: 9 }}
        py={2}
        buttonGroupProps={{
          gap: 0,
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: { sm: 'center', md: 'flex-start' },
        }}
      >
        <Typography variant="bodyText2">Let us help! Give us a call or send us an email.</Typography>
      </SupportContact>

      <Box my={5}>
        <Checkbox checked={isTermsAccepted} name="agree" onChange={toggleTerms}>
          I understand that by removing {supplier.name}, I will lose access at the end of my current billing cycle{' '}
          {currentPeriodEnd ? `on ${getFormattedDateFromISO(currentPeriodEnd)}` : ''}.
        </Checkbox>
      </Box>

      <ModalButtons separated css={styles.modalButton}>
        <Button
          color="negative"
          disabled={!isTermsAccepted || isSubmitting}
          isLoading={isSubmitting}
          onClick={onSubmit}
          data-testid="removeTireSupplierButton"
        >
          Remove {supplier.name}
        </Button>

        <Button variant="text" onClick={onCancel}>
          Keep me subscribed
        </Button>
      </ModalButtons>
    </>
  );
};
