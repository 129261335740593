import { createBaseProductFromQuery } from './createBaseProductFromQuery';
import type { SupplierAccount } from 'models';
import type { FullProductFragment } from 'store/queries/product/FullProductFragment.generated';

type Params = {
  account?: SupplierAccount | null;
  product: FullProductFragment;
  vehicleId?: string | null;
  filterEnhancements?: boolean;
  showRetailPrice?: boolean;
};

export const createFullProductFromQuery = (params: Params) => {
  const model = createBaseProductFromQuery(params);

  return model;
};
