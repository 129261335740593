import { css } from '@emotion/react';

export const styles = {
  mediaWrapper: (shouldAspectRatio: boolean) => css`
    overflow: hidden;
    flex: 1 1 100%;
    height: 100%;
    ${shouldAspectRatio && `aspect-ratio: 16 / 9`};
  `,
  video: css`
    width: 100%;
    height: 100%;
    border: 0;
  `,
  image: (hasImageViewer: boolean = false) => css`
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;

    ${hasImageViewer && 'cursor: pointer;'};
  `,
};
