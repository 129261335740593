import { makeContext } from '@partstech/ui/utils';
import type { Highlights, AddToCartPayload } from 'features/cart';
import type { Cart, CartError, CartOrder, CartOrderItem, CartTotals } from 'models';
import type { AddItemToActiveCartPayload, AddItemToActiveCartResult } from 'shared/api';
import type { Mitchell1ShoppingCart } from 'shared/api/rest/gen/shop';

export type CartContextValue = {
  isLoading: boolean;
  isFetching: boolean;
  cart: Cart | null;
  showRetailPrice: boolean;
  highlights: Highlights;
  isRemoving: boolean;
  orderErrors: CartError[];
  partErrors: CartError[];
  paidOrders: CartOrder[];
  mitchell1Payload: Mitchell1ShoppingCart | null;
  poNumberErrors: Record<string, string | null>;
  totals: CartTotals;
  shouldConfirmShipping: boolean;
  requiredPoNumbers: boolean;
  disabledForSubmitting: boolean;
  clearPaidOrders: () => void;
  addHighlights: (highlights: Highlights) => void;
  removeOrderItem: (orderItem: CartOrderItem, orderId: string) => void;
  removeOrder: (cartOrder: CartOrder) => void;
  changeQuantity: (orderItem: CartOrderItem, quantity: number) => void;
  changeGlobalPoNumber: (poNumber: string) => void;
  changePoNumber: (poNumber: string, cartOrder: CartOrder) => void;
  buy: () => void;
  submit: () => void;
  addToCart: (item: AddToCartPayload, onFinally?: (response: AddItemToActiveCartPayload) => void) => void;
  addToCartMultiple: (items: AddToCartPayload[], onFinally?: (response: AddItemToActiveCartResult[]) => void) => void;
};

export const [useCart, CartContextProvider] = makeContext<CartContextValue>();
