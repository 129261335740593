import { css } from '@emotion/react';
import { Box, Scrollbar } from '@partstech/ui';
import { memo, useCallback } from 'react';
import { FileCell } from './FileCell';
import type { BoxProps, Theme } from '@partstech/ui';
import type { MediaFile } from 'models';
import type { ReactNode } from 'react';

const styles = {
  wrapper: (isVertical: boolean, hasMediaControls: boolean) => css`
    display: grid;
    ${!isVertical && hasMediaControls && 'grid-template-columns: 1fr auto auto'};
  `,
  scrollbar: (theme: Theme) => css`
    flex-shrink: 0;
    max-height: ${theme.sizing(200)};
  `,
};

type Props = {
  files: MediaFile[];
  currentIndex: number;
  isVertical: boolean;
  hasMediaControls: boolean;
  adornmentAfter?: ReactNode;
  onClick: (index: number) => void;
} & BoxProps;

export const FilesCarousel = memo(
  ({ files, currentIndex, isVertical, hasMediaControls, adornmentAfter, onClick, ...boxProps }: Props) => {
    const handleClick = useCallback(
      (index: number) => () => {
        if (index === currentIndex) {
          return;
        }

        onClick(index);
      },
      [currentIndex, onClick]
    );

    return (
      <Box css={styles.wrapper(isVertical, hasMediaControls)} {...boxProps}>
        <Scrollbar css={styles.scrollbar} data-testid="filesCarousel">
          <Box display="inline-flex" flexDirection={isVertical ? 'column' : 'row'} gap={2}>
            {files.map((file, index) => {
              const isSelected = index === currentIndex;

              return (
                <FileCell
                  key={`${file.preview}_${index}`}
                  file={file}
                  index={index}
                  isSelected={isSelected}
                  onClick={handleClick(index)}
                />
              );
            })}
          </Box>
        </Scrollbar>

        {adornmentAfter && adornmentAfter}
      </Box>
    );
  }
);
