/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { FullLocalInventoryProductFragmentDoc } from './FullLocalInventoryProductFragment.generated';
import type * as Types from '../../../shared/api/graphql/graphql';

export type GetLocalInventoryProductQueryVariables = Types.Exact<{
  localInventoryUID: Types.Scalars['String']['input'];
  vehicleId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type GetLocalInventoryProductQuery = {
  __typename?: 'Query';
  localInventoryProduct?: {
    __typename?: 'LocalInventoryProduct';
    marketingDescriptions: Array<Types.Scalars['String']['input']>;
    localInventoryUID: string;
    customerPrice?: Types.Scalars['BigDecimal']['input'] | null;
    hasFitment: Types.Scalars['Boolean']['input'];
    listPrice?: Types.Scalars['BigDecimal']['input'] | null;
    quantity: number;
    partNumber: Types.Scalars['String']['input'];
    partNumberId: Types.Scalars['String']['input'];
    price?: Types.Scalars['BigDecimal']['input'] | null;
    sortHash?: Types.Scalars['String']['input'] | null;
    title: Types.Scalars['String']['input'];
    attachments: Array<{
      __typename?: 'LocalInventoryProductAttachment';
      url: Types.Scalars['String']['input'];
      filename: Types.Scalars['String']['input'];
      group?: Types.Scalars['String']['input'] | null;
    }>;
    diagrams?: Array<{
      __typename?: 'Diagram';
      id: string;
      category?: { __typename?: 'PartCategory'; id: string } | null;
    }> | null;
    productAttributes: Array<{
      __typename?: 'LocalInventoryProductAttribute';
      name: Types.Scalars['String']['input'];
      values: Array<Types.Scalars['String']['input']>;
    }>;
    extendedInformation: Array<{
      __typename?: 'LocalInventoryProductExtendedInformation';
      name: Types.Scalars['String']['input'];
      value: Types.Scalars['String']['input'];
    }>;
    freightPackage?: {
      __typename?: 'LocalInventoryProductFreightPackage';
      weight?: Types.Scalars['Float']['input'] | null;
      merchandising?: {
        __typename?: 'LocalInventoryProductDimensions';
        height: Types.Scalars['Float']['input'];
        length: Types.Scalars['Float']['input'];
        width: Types.Scalars['Float']['input'];
      } | null;
      shipping?: {
        __typename?: 'LocalInventoryProductDimensions';
        height: Types.Scalars['Float']['input'];
        length: Types.Scalars['Float']['input'];
        width: Types.Scalars['Float']['input'];
      } | null;
    } | null;
    specificMarketingDescriptions: Array<{
      __typename?: 'LocalInventoryProductDescription';
      code: Types.Scalars['String']['input'];
      label: Types.Scalars['String']['input'];
      values: Array<Types.Scalars['String']['input']>;
    }>;
    interchanges?: Array<{
      __typename?: 'LocalInventoryProductInterchange';
      brandName?: Types.Scalars['String']['input'] | null;
      partNumber: Types.Scalars['String']['input'];
      type: Types.LocalInventoryProductInterchangeType;
    }> | null;
    warnings: Array<
      | { __typename?: 'LocalInventoryProductWarningAttachment'; url: Types.Scalars['String']['input'] }
      | { __typename?: 'LocalInventoryProductWarningImage'; url: Types.Scalars['String']['input'] }
      | { __typename?: 'LocalInventoryProductWarningText'; text: Types.Scalars['String']['input'] }
    >;
    attributes: Array<{
      __typename?: 'LocalInventoryProductAttribute';
      name: Types.Scalars['String']['input'];
      values: Array<Types.Scalars['String']['input']>;
    }>;
    brand?: {
      __typename?: 'Brand';
      id: string;
      lineCode?: Types.Scalars['String']['input'] | null;
      logo?: Types.Scalars['String']['input'] | null;
      name: Types.Scalars['String']['input'];
    } | null;
    descriptions: Array<{
      __typename?: 'LocalInventoryProductDescription';
      code: Types.Scalars['String']['input'];
      label: Types.Scalars['String']['input'];
      values: Array<Types.Scalars['String']['input']>;
    }>;
    images: Array<{
      __typename?: 'LocalInventoryProductImage';
      full: Types.Scalars['String']['input'];
      medium: Types.Scalars['String']['input'];
      preview: Types.Scalars['String']['input'];
    }>;
    interchangeFor?: {
      __typename?: 'LocalInventoryProductInterchange';
      brandName?: Types.Scalars['String']['input'] | null;
      partNumber: Types.Scalars['String']['input'];
      type: Types.LocalInventoryProductInterchangeType;
    } | null;
    image360Groups: Array<{
      __typename?: 'LocalInventoryProductImage360Group';
      images: Array<{
        __typename?: 'LocalInventoryProductImage';
        full: Types.Scalars['String']['input'];
        preview: Types.Scalars['String']['input'];
        medium: Types.Scalars['String']['input'];
      }>;
    }>;
    notes: Array<{ __typename?: 'LocalInventoryProductNotes'; group: Array<Types.Scalars['String']['input']> }>;
    partType?: { __typename?: 'PartType'; id: string; name: Types.Scalars['String']['input'] } | null;
    variations: Array<{
      __typename?: 'LocalInventoryProductVariation';
      variationID: Types.Scalars['String']['input'];
      attributes: Array<{
        __typename?: 'LocalInventoryProductAttribute';
        name: Types.Scalars['String']['input'];
        values: Array<Types.Scalars['String']['input']>;
      }>;
      notes: Array<{ __typename?: 'LocalInventoryProductNotes'; group: Array<Types.Scalars['String']['input']> }>;
    }>;
    videos: Array<{
      __typename?: 'LocalInventoryProductVideo';
      full: Types.Scalars['String']['input'];
      medium: Types.Scalars['String']['input'];
      preview: Types.Scalars['String']['input'];
    }>;
  } | null;
};

export const GetLocalInventoryProductDocument = `
    query GetLocalInventoryProduct($localInventoryUID: String!, $vehicleId: ID) {
  localInventoryProduct(
    localInventoryUID: $localInventoryUID
    vehicleId: $vehicleId
  ) {
    ...FullLocalInventoryProduct
  }
}
    ${FullLocalInventoryProductFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetLocalInventoryProduct: build.query<GetLocalInventoryProductQuery, GetLocalInventoryProductQueryVariables>({
      query: (variables) => ({ document: GetLocalInventoryProductDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetLocalInventoryProductQuery, useLazyGetLocalInventoryProductQuery } = injectedRtkApi;
